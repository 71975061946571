import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./assets/style/main.scss";
import Layout from "./components/layout/Layout";
import CallTable from "./pages/call-table/CallTable";
import CallDetails from "./pages/call-details/CallDetails";
import CallPlayerHiHi from "./pages/call-details/CallPlayerHiHi";
import Insights from "./pages/insight/Insights";
import Scorecards from "./pages/settings/scorecards/Scorecards";
import ScorecardDetail from "./pages/settings/scorecards/ScorecardDetail";
import Topics from "./pages/settings/topics/Topics";
import UserView from "./pages/settings/user-control/user-management/user-profile/UserView";
import CallResult from "./pages/settings/call-result/CallResult";
import useAuth from "./hooks/useAuth";
import { setUpAuthAxiosInterceptors } from "./utils/customAxios";
import ProtectedRoutes from "./components/ProtectedRoutes";
import SharedResourceRedirect from "./pages/SharedResourceRedirect";
import SharedRoutes from "./components/SharedRoutes";
import NotFoundError from "./pages/error/NotFoundError";
import InternalServerError from "./pages/error/InternalServerError";
import UnauthorizedAccess from "./pages/error/UnauthorizedAccess";
import SystemSettings from "./pages/settings/system-settings/SystemSettings";
import Changepassword from "./pages/auth/change-password/ChangePassword";
import Login from "./pages/auth/Login";
import UserControl from "./pages/settings/user-control/UserControl";
import NotificationSetup from "./pages/settings/notification-setup/NotificationSetup";
import CustomerPlatform from "./pages/customer-platform-detail/CustomerPlatform";
import UnauthorizedAccessWithMessage from "./pages/error/UnauthorizedAccessWithMessage";
import MsTeamsAdminAction from "./pages/customer-platform-detail/MsTeamsAdminAction";

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  setUpAuthAxiosInterceptors(location, navigate, auth?.setUserAuthToken);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home", { replace: true });
    }
  }, []);

  useEffect(() => {
    switch(location.pathname) { 
      case "/login": { 
        document.title = "Login";
        break; 
      }
      case "/share": { 
        document.title = "Share";
        break; 
      }
      case "/not-found": { 
        document.title = "Not Found";
        break; 
      }
      case "/error": { 
        document.title = "Error";
        break; 
      }
      case "/unauthorized": { 
        document.title = "Unauthorized";
        break; 
      }
      case "/unauthorized-calldetail": { 
        document.title = "Unauthorized Call Detail";
        break; 
      }
      default: {
        break; 
      } 
    } 
  }, [window.location.pathname])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/login" element={<Login />} />

        <Route path="/share" element={<SharedResourceRedirect />} />
        <Route path="/not-found" element={<NotFoundError />} />
        <Route path="/error" element={<InternalServerError />} />
        <Route path="/unauthorized" element={<UnauthorizedAccess />} />
        <Route
          path="/unauthorized-calldetail"
          element={<UnauthorizedAccessWithMessage />}
        />
        {/* <Route path="/resetpassword" element={<Resetpassword />} /> */}

        {/* shared resource routes */}
        <Route path="/shared" element={<SharedRoutes />}>
          <Route
            path="/shared/calldetail/:platformCallDetailId"
            element={<CallDetails sharedProp={true} />}
          />
        </Route>

        {/* protected routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/search" element={<CallTable />} />
          <Route
            path="/calldetail/:platformCallDetailId"
            element={<CallDetails />}
          />
          <Route path="/home" element={<Insights />} />
          <Route path="/settings/user-control" element={<UserControl />} />
          <Route
            path="/settings/user-control/user/:userId"
            element={<UserView />}
          />
          <Route path="/settings/scorecards" element={<Scorecards />} />
          <Route
            path="/settings/scorecardDetail/:scorecardId"
            element={<ScorecardDetail />}
          />
          <Route path="/settings/topics" element={<Topics />} />
          <Route path="/settings/outcomes" element={<CallResult />} />
          <Route path="/settings/system" element={<SystemSettings />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route
            path="/settings/notification-setup"
            element={<NotificationSetup />}
          />
          <Route path="/customer-platform" element={<CustomerPlatform />} />
          <Route path="/customer-platform/admin-action" element={<MsTeamsAdminAction />} />
        </Route>

        {/* Catch all */}
        <Route path="*" element={<NotFoundError />} />
      </Route>
      <Route path="/call-player-hihi" element={<CallPlayerHiHi />} />
    </Routes>
  );
};

export default App;
