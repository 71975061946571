import React, { useContext, useEffect, useState } from "react";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridColumn,
  GridCellProps,
  GridNoRecords,
  GridRowProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { axiosWithAuth } from "../../../utils/customAxios";
import { Loader } from "@progress/kendo-react-indicators";
import { CallResult } from "../../../types/call";
import UpsertCallResultDialog from "./UpsertCallResultDialog";
import { AccessPermissionEnum } from "../../../enums/accessPermissionEnum";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import useAuth from "../../../hooks/useAuth";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import { AxiosError } from "axios";
import { ReasonCodeInstance } from "../../../types/call/CallResultReasonCode";
import EditReasonCodeDialogBox from "./EditReasonCodeDialogBox";
import NotFoundError from "../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import reasonCodeService from "../../../services/reasonCode.service";
import callResultService from "../../../services/callResult.service";
import LoadingOverlay from "../../../components/LoadingOverlay";

export interface CallResultWithReasonCode {
  callResult: CallResult;
  reasonCode: ReasonCodeInstance[];
}

const CustomCellTopicName = (props: GridCellProps) => {
  return (
    <td className="uCardCol">
      <div className="tblUsr">
        <div className="topUsrAreaPic">
          <div className="topUsrAreaPic-tx">
            <span className="text-primary font-weight-bold cursor-default text-decoration-none float-left w-100 line-height-1">
              {props.dataItem.callResult.text}
            </span>
          </div>
        </div>
      </div>
    </td>
  );
};

const CallResults: React.FC = () => {
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const auth = useAuth();
  const localeCtx = useLocale();
  const swal = useSwal();
  const [search, setSearch] = useState<string>("");
  const [callResultList, setCallResultList] = useState<
    CallResultWithReasonCode[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(initialDataState);
  const [callResultEdit, setCallResultEdit] = useState<
    CallResultWithReasonCode | undefined
  >();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["CallResult"]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [reasonCodeLoader, setReasonCodeLoader] = useState(true);
  const [callsFetched, setCallsFetched] = useState(false);
  const [reasonCodePopup, setReasonCodePopup] = useState(false);
  const [reasonCodeEdit, setReasonCodeEdit] = useState<
    ReasonCodeInstance | undefined
  >();
  const [reasonCodes, setReasonCodes] = useState<
    ReasonCodeInstance[] | undefined
  >(undefined);
  const [sort, setSort] = React.useState([] as SortDescriptor[]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["CallResult"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getAllCallResult();
  }, []);

  useEffect(() => {
    getAllReasonCodes();
  }, [callResultList]);

  useEffect(() => {
    if (callsFetched) {
      fetchReasonCode();
    }
  }, [callsFetched]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("CallResult");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["CallResult"]
      );
      localeCtx?.setPreviousAppLocale("CallResult");
      console.log(localeCtx?.localeSwitchFailed);
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handlePageChange = (event: any) => {
    setPage(event.page);
  };

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    setPage(initialDataState);
  };

  const toggleDialog = () => {
    setVisible(!visible);
    setCallResultEdit(undefined);
  };

  const toggleReasonCodeDialog = (data: ReasonCodeInstance | undefined) => {
    setReasonCodePopup(!reasonCodePopup);
    setReasonCodeEdit(data);
    setCallResultEdit(undefined);
  };

  const fetchReasonCode = async () => {
    try {
      const CallResultWithReasonCodes: CallResultWithReasonCode[] = [];
      for (let i = 0; i < callResultList.length; i++) {
        const response = await reasonCodeService.getReasonCodesForOutcome(
          callResultList[i].callResult.id
        );
        const CallResultWithReasonCodeObject: CallResultWithReasonCode = {
          callResult: callResultList[i].callResult,
          reasonCode: response,
        };
        CallResultWithReasonCodes.push(CallResultWithReasonCodeObject);
      }
      setCallResultList(CallResultWithReasonCodes);
    } catch (error) {
      const CallResultWithReasonCodes: CallResultWithReasonCode[] = [];
      for (let i = 0; i < callResultList.length; i++) {
        const CallResultWithReasonCodeObject: CallResultWithReasonCode = {
          callResult: callResultList[i].callResult,
          reasonCode: [],
        };
        CallResultWithReasonCodes.push(CallResultWithReasonCodeObject);
      }
      setCallResultList(CallResultWithReasonCodes);
    } finally {
      setCallsFetched(false);
      setReasonCodeLoader(false);
    }
  };

  const getAllCallResult = async () => {
    try {
      const response = await callResultService.getAllCallResults();
      const callResult: CallResultWithReasonCode[] = [];
      for (let i = 0; i < response.length; i++) {
        callResult.push({ callResult: response[i], reasonCode: [] });
      }
      setCallResultList(callResult);
      setCallsFetched(true);
    } catch (error) {
      setCallResultList([]);
      setReasonCodeLoader(false);
    } finally {
      setLoading(false);
    }
  };

  const getAllReasonCodes = async () => {
    try {
      const response = await reasonCodeService.getAllReasonCodes();
      setReasonCodes(response);
    } catch (error) {
      setReasonCodes([]);
    }
  };

  const searchCallResult = (callResult: CallResultWithReasonCode) => {
    const searchVal = search.trim().toLocaleLowerCase();
    if (callResult?.callResult?.text?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const upsertCallResult = (
    isUpdated: boolean,
    upsertCallResult: CallResultWithReasonCode
  ) => {
    if (isUpdated) {
      const updatedCallResult: CallResultWithReasonCode[] = [];
      callResultList?.forEach((t) => {
        if (t.callResult.id === upsertCallResult.callResult.id) {
          updatedCallResult.push(upsertCallResult);
        } else {
          updatedCallResult.push(t);
        }
      });
      setCallResultList(updatedCallResult);
    } else {
      const updatedCallResults = [...callResultList, upsertCallResult];
      setCallResultList(updatedCallResults);
      if (updatedCallResults.length % page.take !== 0) {
        setPage({
          skip: Math.floor(updatedCallResults.length / page.take) * page.take,
          take: page.take,
        });
      }
    }
  };

  const upsertReasonCode = (
    isUpdated: boolean,
    reasonCode: ReasonCodeInstance | undefined,
    updatedCall: CallResultWithReasonCode | undefined
  ) => {
    if (isUpdated) {
      const updatedCallResult: CallResultWithReasonCode[] = [];
      callResultList?.forEach((t) => {
        const tempReasonCodes: ReasonCodeInstance[] = [];
        t.reasonCode.forEach((rc) => {
          if (rc.id === reasonCode?.id) {
            tempReasonCodes.push(reasonCode);
          } else {
            tempReasonCodes.push(rc);
          }
        });
        updatedCallResult.push({
          callResult: t.callResult,
          reasonCode: tempReasonCodes,
        });
      });
      setCallResultList(updatedCallResult);
    } else {
      const updatedCallResult: CallResultWithReasonCode[] = [];
      callResultList?.forEach((t) => {
        if (t.callResult.id === updatedCall?.callResult.id) {
          updatedCallResult.push(updatedCall);
        } else {
          updatedCallResult.push(t);
        }
      });
      setCallResultList(updatedCallResult);
    }
  };

  const rowRender = (
    row: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const expanded = props.dataItem.callResult.isArchived;
    const trProps: any = {
      className: expanded ? "archiveRow" : "",
    };
    return React.cloneElement(
      row,
      { ...trProps },
      row.props.children as React.ReactNode
    );
  };

  const CellReasonCode = (props: GridCellProps) => {
    const toggleReasonCodeDialog = (data: ReasonCodeInstance | undefined) => {
      setReasonCodePopup(!reasonCodePopup);
      setReasonCodeEdit(data);
      setCallResultEdit(props.dataItem);
    };

    return (
      <>
        <td className="mx-th-tag keysButtons">
          <Tooltip
            anchorElement={
              props.dataItem.callResult.isArchived ? "pointer" : "target"
            }
            parentTitle={true}
            position="top"
          >
            <div title={props.dataItem.callResult.isArchived?"Archived":""} className="mx-td-spn line-height-2">
              {reasonCodeLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    textAlign: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <>
                  {props.dataItem.reasonCode.map((rc: ReasonCodeInstance) => {
                    return (
                      <div className="keywordRow">
                        <div
                          style={rc.isArchived ? { opacity: 0.5 } : {}}
                          className={`keywordTrk ${
                            !rc.isArchived && "bg-darkkhaki"
                          }`}
                        >
                          <span>{rc.name}</span>
                          <span className="keysBtnGroup">
                            <Button
                              onClick={() => toggleReasonCodeDialog(rc)}
                              className="btn keysBtn line-height-1"
                              title="Edit"
                              disabled={rc.isArchived}
                            >
                              <i className="bi bi-pencil fs-14"></i>
                            </Button>
                            <Button
                              onClick={() =>
                                deleteReasonCode(rc, props.dataItem.callResult)
                              }
                              className="btn keysBtn line-height-1"
                              title="Delete"
                              disabled={rc.isArchived}
                            >
                              <i className="bi bi-trash3 fs-14"></i>
                            </Button>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {!reasonCodeLoader && (
                <div
                  className="keywordTrk p-0 d-flex align-items-center justify-content-center"
                  style={{ height: "28px", width: "28px" }}
                >
                  <Button
                    onClick={() => toggleReasonCodeDialog(undefined)}
                    disabled={props.dataItem.callResult.isArchived}
                    className="btn bg-transparent border-none text-black-8 line-height-1 p-0"
                    title="Add New"
                  >
                    <i className="bi bi-plus fs-24 line-height-0"></i>
                  </Button>
                </div>
              )}
            </div>
          </Tooltip>
        </td>
      </>
    );
  };

  const CustomCellWithActions = (props: GridCellProps) => {
    const toggleDialogEdit = () => {
      setCallResultEdit(props.dataItem);
      setVisible(!visible);
    };
    const hasCallResultManagementPermission = auth?.checkUserAccess(
      AccessPermissionEnum.ManageOutcomes
    );
    const deleteCallResult = async () => {
      const swalOptions: SweetAlertOptions<any, any> = {
        title: `${
          translationsLoading
            ? "Are you sure?"
            : fetchLabelKeyTranslation("SwtAltCnfTitle", "Are you sure?")
        }`,
        text: `${
          translationsLoading
            ? "You won't be able to revert this!"
            : fetchLabelKeyTranslation(
                "SwtAltCnfText",
                "You won't be able to revert this!"
              )
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Yes, delete it!"
            : fetchLabelKeyTranslation(
                "SwtAltDeleteCfnBtnText",
                "Yes, delete it!"
              )
        }`,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelText", "Cancel")
        }`,
      };
      const res = await swal.fire(swalOptions);
      if (res.isConfirmed) {
        try {
          await callResultService.deleteCallResult(
            props.dataItem.callResult.id
          );
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: `${
              translationsLoading
                ? "Deleted"
                : fetchLabelKeyTranslation("SwtAltDeleteSucessTitle", "Deleted")
            }`,
            text: `${
              translationsLoading
                ? "Call Outcome has been deleted successfully"
                : fetchLabelKeyTranslation(
                    "SwtAltDeleteSucessText",
                    "Call Outcome has been deleted successfully"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
          const updatedList = callResultList?.filter((item) => {
            return item.callResult.id !== props.dataItem.callResult.id;
          });
          setCallResultList(updatedList);
          const gridDataResult = process(
            updatedList.filter((c) => searchCallResult(c)),
            page
          );
          if (gridDataResult.data.length === 0 && gridDataResult.total > 0) {
            const currentPageNumber = Math.floor(
              gridDataResult.total / page.take
            );
            setPage({
              skip:
                gridDataResult.total % page.take === 0
                  ? (currentPageNumber - 1) * page.take
                  : currentPageNumber * page.take,
              take: page.take,
            });
          }
        } catch (err) {
          if (err instanceof AxiosError) {
            if (err.response?.status === 405) {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "info",
                title: `${
                  translationsLoading
                    ? "Call Outcome cannot be deleted"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedTitle",
                        "Call Outcome cannot be deleted"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "You can archive the Call Outcome"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedText",
                        "You can archive the Call Outcome"
                      )
                }`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `${
                  translationsLoading
                    ? "Yes, Archive it!"
                    : fetchLabelKeyTranslation(
                        "SwtAltCfnArchiveText",
                        "Yes, Archive it!"
                      )
                }`,
                cancelButtonText: `${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`,
              };
              const dialogRes = await swal.fire(swalOptions);
              if (dialogRes.isConfirmed) {
                let updatedCallResult: CallResultWithReasonCode =
                  props.dataItem;
                updatedCallResult.callResult.isArchived = true;

                try {
                  updatedCallResult.callResult =
                    await callResultService.updateCallResult(
                      updatedCallResult.callResult.id,
                      updatedCallResult.callResult
                    );
                  const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "success",
                    title: `${
                      translationsLoading
                        ? "Outcome Archived"
                        : fetchLabelKeyTranslation(
                            "SwlAltArchiveSuccessTitle",
                            "Outcome Archived"
                          )
                    }`,
                    text: `${
                      translationsLoading
                        ? "Call Outcome has been archived successfully"
                        : fetchLabelKeyTranslation(
                            "SwlAltArchiveSuccessText",
                            "Call Outcome has been archived successfully"
                          )
                    }`,
                  };
                  swal.fire(swalOptions);
                  const updatedList = callResultList?.map((c) => {
                    if (c.callResult.id === props.dataItem.id) {
                      return updatedCallResult;
                    }
                    return c;
                  });
                  setCallResultList(updatedList);
                } catch (err) {
                  if (err instanceof AxiosError) {
                    const swalOptions: SweetAlertOptions<any, any> = {
                      icon: "error",
                      title: `${
                        translationsLoading
                          ? "Error"
                          : fetchLabelKeyTranslation("ErrorTitle", "Error")
                      }`,
                      text: `${
                        translationsLoading
                          ? "Could not update the Call Outcome."
                          : fetchLabelKeyTranslation(
                              "ErrorUpdateText",
                              "Could not update the Call Outcome."
                            )
                      }`,
                    };
                    swal.fire(swalOptions);
                  }
                }
              }
            } else {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("ErrorTitle", "Error")
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete the Call Outcome."
                    : fetchLabelKeyTranslation(
                        "ErrorText",
                        "Could not delete the Call Outcome."
                      )
                }`,
              };
              swal.fire(swalOptions);
            }
          }
        }
      }
    };

    return (
      <td className="text-right">
        <Tooltip
          anchorElement={
            props.dataItem.callResult.isArchived ? "pointer" : "target"
          }
          position={"top"}
          parentTitle={true}
        >
          {props.dataItem.callResult.isArchived ? (
          <div title={props.dataItem.callResult.isArchived ? "Archived" : ""}>
            <Button
              style={{ cursor: "default" }}
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
            >
              <span
                title="Archived"
                className="fs-17 line-height-1 text-primary"
              >
                <i className="bi bi-archive"></i>
              </span>
            </Button>
          </div>
          ) : (
            <span title={props.dataItem.callResult.isArchived ? "Archived" : ""} className="iBtn d-flex justify-content">
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                disabled={
                  props.dataItem.callResult.isArchived ||
                  !hasCallResultManagementPermission
                }
                onClick={toggleDialogEdit}
              >
                <span
                  className="fs-17 line-height-1 text-primary"
                  title={`${
                    translationsLoading
                      ? "Edit"
                      : fetchLabelKeyTranslation("TextEdit", "Edit")
                  }`}
                >
                  <i className="bi bi-pencil"></i>
                </span>
              </Button>
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                onClick={deleteCallResult}
                disabled={
                  props.dataItem.callResult.isArchived ||
                  !hasCallResultManagementPermission
                }
              >
                <span
                  className="fs-17 line-height-1 text-primary"
                  title={`${
                    translationsLoading
                      ? "Delete"
                      : fetchLabelKeyTranslation("TextDelete", "Delete")
                  }`}
                >
                  <i className="bi bi-trash3"></i>
                </span>
              </Button>
            </span>
          )}
        </Tooltip>
      </td>
    );
  };

  const deleteReasonCode = async (
    reasonCode: ReasonCodeInstance,
    callResult: CallResult
  ) => {
    const swalOptions: SweetAlertOptions<any, any> = {
      title: `${
        translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation("SwtAltCnfTitle", "Are you sure?")
      }`,
      text: `${
        translationsLoading
          ? "You won't be able to revert this!"
          : fetchLabelKeyTranslation(
              "SwtAltCnfText",
              "You won't be able to revert this!"
            )
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Yes, delete it!"
          : fetchLabelKeyTranslation(
              "SwtAltDeleteCfnBtnText",
              "Yes, delete it!"
            )
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelText", "Cancel")
      }`,
    };
    const res = await swal.fire(swalOptions);
    if (res.isConfirmed) {
      try {
        await reasonCodeService.deleteReasonCode(reasonCode.id);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Deleted"
              : fetchLabelKeyTranslation("SwtAltDeleteSucessTitle", "Deleted")
          }`,
          text: `${
            translationsLoading
              ? "Reason Code has been deleted successfully"
              : fetchLabelKeyTranslation(
                  "SwtAltRCDeleteSucessText",
                  "Reason Code has been deleted successfully"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
        const updatedCallResult: CallResultWithReasonCode[] = [];
        callResultList?.forEach((t) => {
          const tempReasonCodes: ReasonCodeInstance[] = [];
          t.reasonCode.forEach((rc) => {
            if (rc.id !== reasonCode?.id) {
              tempReasonCodes.push(rc);
            }
          });
          updatedCallResult.push({
            callResult: t.callResult,
            reasonCode: tempReasonCodes,
          });
        });
        setCallResultList(updatedCallResult);
        const gridDataResult = process(
          updatedCallResult.filter((c) => searchCallResult(c)),
          page
        );
        if (gridDataResult.data.length === 0 && gridDataResult.total > 0) {
          const currentPageNumber = Math.floor(
            gridDataResult.total / page.take
          );
          setPage({
            skip:
              gridDataResult.total % page.take === 0
                ? (currentPageNumber - 1) * page.take
                : currentPageNumber * page.take,
            take: page.take,
          });
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 405) {
            const dialogRes = await swal.fire({
              icon: "info",
              title: `${
                translationsLoading
                  ? "Reason Code cannot be deleted"
                  : fetchLabelKeyTranslation(
                      "SwtAltRCDeletedFailedTitle",
                      "Reason Code cannot be deleted"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "You can archive the Reason Code"
                  : fetchLabelKeyTranslation(
                      "SwtAltRCDeletedFailedText",
                      "You can archive the Reason Code"
                    )
              }`,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: `${
                translationsLoading
                  ? "Yes, Archive it!"
                  : fetchLabelKeyTranslation(
                      "SwtAltCfnArchiveText",
                      "Yes, Archive it!"
                    )
              }`,
              cancelButtonText: `${
                translationsLoading
                  ? "Cancel"
                  : fetchLabelKeyTranslation("CancelText", "Cancel")
              }`,
            });
            if (dialogRes.isConfirmed) {
              let updatedReasonCode: ReasonCodeInstance = reasonCode;
              updatedReasonCode.isArchived = true;
              updatedReasonCode.description = "";
              const updatedReasonCodeResponse =
                await reasonCodeService.updateReasonCode(
                  updatedReasonCode,
                  reasonCode.id
                );
              swal.fire({
                icon: "success",
                title: `${
                  translationsLoading
                    ? "Reason Code Archived"
                    : fetchLabelKeyTranslation(
                        "SwlAltRCArchiveSuccessTitle",
                        "Reason Code Archived"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Reason Code has been archived successfully"
                    : fetchLabelKeyTranslation(
                        "SwlAltRCArchiveSuccessText",
                        "Reason Code has been archived successfully"
                      )
                }`,
              });
              const updatedList: CallResultWithReasonCode[] =
                callResultList?.map((cResult: CallResultWithReasonCode) => {
                  if (cResult.callResult.id === callResult.id) {
                    cResult.reasonCode.map((code: ReasonCodeInstance) => {
                      if (code.id === updatedReasonCodeResponse.id) {
                        code.isArchived = true;
                      }
                      return code;
                    });
                  }
                  return cResult;
                });
              setCallResultList(updatedList);
            }
          } else {
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "error",
              title: `${
                translationsLoading
                  ? "Error"
                  : fetchLabelKeyTranslation("ErrorTitle", "Error")
              }`,
              text: `${
                translationsLoading
                  ? "Could not delete the Reason Code."
                  : fetchLabelKeyTranslation(
                      "ErrorTextRC",
                      "Could not delete the Reason Code."
                    )
              }`,
            };
            swal.fire(swalOptions);
          }
        }
      }
    }
  };

  const customCellMobile = (props: GridCellProps) => {
    const toggleDialogEdit = () => {
      setCallResultEdit(props.dataItem);
      setVisible(!visible);
    };
    const toggleReasonCodeDialog = (data: ReasonCodeInstance | undefined) => {
      setReasonCodePopup(!reasonCodePopup);
      setReasonCodeEdit(data);
      setCallResultEdit(props.dataItem);
    };
    const deleteCallResult = async () => {
      const swalOptions: SweetAlertOptions<any, any> = {
        title: `${
          translationsLoading
            ? "Are you sure?"
            : fetchLabelKeyTranslation("SwtAltCnfTitle", "Are you sure?")
        }`,
        text: `${
          translationsLoading
            ? "You won't be able to revert this!"
            : fetchLabelKeyTranslation(
                "SwtAltCnfText",
                "You won't be able to revert this!"
              )
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Yes, delete it!"
            : fetchLabelKeyTranslation(
                "SwtAltDeleteCfnBtnText",
                "Yes, delete it!"
              )
        }`,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelText", "Cancel")
        }`,
      };
      const res = await swal.fire(swalOptions);
      if (res.isConfirmed) {
        try {
          await callResultService.deleteCallResult(props.dataItem.callResult.id);
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: `${
              translationsLoading
                ? "Deleted"
                : fetchLabelKeyTranslation("SwtAltDeleteSucessTitle", "Deleted")
            }`,
            text: `${
              translationsLoading
                ? "Call Outcome has been deleted successfully"
                : fetchLabelKeyTranslation(
                    "SwtAltDeleteSucessText",
                    "Call Outcome has been deleted successfully"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
          const updatedList = callResultList?.filter((item) => {
            return item.callResult.id !== props.dataItem.callResult.id;
          });
          setCallResultList(updatedList);
          const gridDataResult = process(
            updatedList.filter((c) => searchCallResult(c)),
            page
          );
          if (gridDataResult.data.length === 0 && gridDataResult.total > 0) {
            const currentPageNumber = Math.floor(
              gridDataResult.total / page.take
            );
            setPage({
              skip:
                gridDataResult.total % page.take === 0
                  ? (currentPageNumber - 1) * page.take
                  : currentPageNumber * page.take,
              take: page.take,
            });
          }
        } catch (err) {
          if (err instanceof AxiosError) {
            if (err.response?.status === 405) {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "info",
                title: `${
                  translationsLoading
                    ? "Call Outcome cannot be deleted"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedTitle",
                        "Call Outcome cannot be deleted"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "You can archive the Call Outcome"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedText",
                        "You can archive the Call Outcome"
                      )
                }`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `${
                  translationsLoading
                    ? "Yes, Archive it!"
                    : fetchLabelKeyTranslation(
                        "SwtAltCfnArchiveText",
                        "Yes, Archive it!"
                      )
                }`,
                cancelButtonText: `${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`,
              };
              const dialogRes = await swal.fire(swalOptions);
              if (dialogRes.isConfirmed) {
                let updatedCallResult: CallResultWithReasonCode =
                  props.dataItem;
                updatedCallResult.callResult.isArchived = true;
                updatedCallResult.callResult =
                  await callResultService.updateCallResult(
                    updatedCallResult.callResult.id,
                    updatedCallResult.callResult
                  );
                const swalOptions: SweetAlertOptions<any, any> = {
                  icon: "success",
                  title: `${
                    translationsLoading
                      ? "Call Outcome Archived"
                      : fetchLabelKeyTranslation(
                          "SwlAltArchiveSuccessTitle",
                          "Call Outcome Archived"
                        )
                  }`,
                  text: `${
                    translationsLoading
                      ? "Call Outcome has been archived successfully"
                      : fetchLabelKeyTranslation(
                          "SwlAltArchiveSuccessText",
                          "Call Outcome has been archived successfully"
                        )
                  }`,
                };
                swal.fire(swalOptions);
                const updatedList = callResultList?.map((c) => {
                  if (c.callResult.id === props.dataItem.callResult.id) {
                    return updatedCallResult;
                  }
                  return c;
                });
                setCallResultList(updatedList);
              }
            } else {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("ErrorTitle", "Error")
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete the Call Outcome."
                    : fetchLabelKeyTranslation(
                        "ErrorText",
                        "Could not delete the Call Outcome."
                      )
                }`,
              };
              swal.fire(swalOptions);
            }
          }
        }
      }
    };

    return (
      <td className="listMobile">
        <div className="listRowMobile">
          <div className="tblUsr float-left w-100 p-r-40 p-b-15 p-l-10">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-tx">
                <span className="text-primary fs-16 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2">
                  {props.dataItem.callResult.text}
                </span>
              </div>
            </div>
          </div>
          <div className="topics float-left w-100 p-t-0 p-b-10 p-l-10">
            <div className="fs-14 font-weight-semi p-r-5">
              {`${
                translationsLoading
                  ? "Reason Code"
                  : fetchLabelKeyTranslation("ReasonCodeText", "Reason Code")
              }`}
            </div>
            <div className="chipLable line-height-2">
              {reasonCodeLoader ? (
                <div style={{ textAlign: "center" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  {props.dataItem.reasonCode.map((rc: ReasonCodeInstance) => {
                    return (
                      <Tooltip
                        anchorElement={
                          props.dataItem.callResult.isArchived
                            ? "pointer"
                            : "target"
                        }
                        parentTitle={true}
                        position="top"
                      >
                        <div className="keywordRow">
                          <div className="keywordTrk bg-darkkhaki">
                            <span>{rc.name}</span>
                            <span className="keysBtnGroup">
                              <Button
                                onClick={() => toggleReasonCodeDialog(rc)}
                                className="btn keysBtn line-height-1"
                                title="Edit"
                              >
                                <i className="bi bi-pencil fs-14"></i>
                              </Button>
                              <Button
                                onClick={() =>
                                  deleteReasonCode(
                                    rc,
                                    props.dataItem.callResult
                                  )
                                }
                                className="btn keysBtn line-height-1"
                                title="Delete"
                              >
                                <i className="bi bi-trash3 fs-14"></i>
                              </Button>
                            </span>
                          </div>
                        </div>
                      </Tooltip>
                    );
                  })}
                </>
              )}
              <div
                className="keywordTrk p-0 d-flex align-items-center justify-content-center"
                style={{ height: "28px", width: "28px" }}
              >
                <Button
                  onClick={() => toggleReasonCodeDialog(undefined)}
                  className="btn bg-transparent border-none text-black-8 line-height-1 p-0"
                  title="Add New"
                  disabled={props.dataItem.callResult.isArchived}
                >
                  <i className="bi bi-plus fs-24 line-height-0"></i>
                </Button>
              </div>
            </div>
          </div>
          {props.dataItem.callResult.isArchived
            ?
              (
              <div className="iconMobileDetail">
                <Button
                  style={{ cursor: "default" }}
                  className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                >
                  <span
                    title="Archived"
                    className="fs-17 line-height-1 text-primary"
                  >
                    <i className="bi bi-archive"></i>
                  </span>
                </Button>
              </div>
              )
           : 
            <div className="iconMobileDetail">
              <span
                className="fs-18 text-primary m-r-15"
                title={`${
                  translationsLoading
                    ? "Edit"
                    : fetchLabelKeyTranslation("TextEdit", "Edit")
                }`}
              >
                <Button
                  className="btn-link text-primary p-0 m-0 fs-18"
                  onClick={toggleDialogEdit}
                >
                  <i className="bi bi-pencil"></i>
                </Button>
              </span>
              <span
                className="fs-18 text-primary"
                title={`${
                  translationsLoading
                    ? "Delete"
                    : fetchLabelKeyTranslation("TextDelete", "Delete")
                }`}
              >
                <Button
                  className="btn-link text-primary p-0 m-0 fs-18"
                  onClick={deleteCallResult}
                >
                  <i className="bi bi-trash3"></i>
               </Button>
             </span>
            </div>
            }
        </div>
      </td>
    );
  };

  const hCellReasonCode = (props: any) => {
    return <span> {`${
      translationsLoading
        ? "Reason Code"
        : fetchLabelKeyTranslation("ReasonCodeText", "Reason Code")
    }`}</span>;
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageOutcomes) ? (
        <UnauthorizedAccess />
      ) : (
        <>
         {translationsLoading && (
         <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
        <div className="callListInner">
          <div className="callListBox">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card cardEffect cardMobile">
                      <div className="card-header d-flex justify-content-between p-0 p-b-12">
                        <span className="fs-13 font-weight-semi d-flex align-items-center">
                          {`${
                            translationsLoading
                              ? "Call Outcomes"
                              : fetchLabelKeyTranslation(
                                  "TextOutcomes",
                                  "Call Outcomes"
                                )
                          }`}
                        </span>
                        <div className="buttons-container hov-transparent d-flex align-itmes-center">
                          <div className="searchBox searchCol m-r-10">
                            <CustomSearchFieldTextInput
                              className="input-search"
                              placeholder={`${
                                translationsLoading
                                  ? "Search..."
                                  : fetchLabelKeyTranslation(
                                      "SearchPlaceholder",
                                      "Search..."
                                    )
                              }`}
                              value={search}
                              updateValue={setSearch}
                              searchTextChangeHandler={onSearchChange}
                              onEscapeKeyFunc={true}
                            />
                          </div>
                          <Button
                            onClick={toggleDialog}
                            className={`btn bg-primary text-white fs-13 fw-normal ${
                              !auth?.checkUserAccess(
                                AccessPermissionEnum.ManageOutcomes
                              )
                                ? "disabledBtn"
                                : ""
                            }`}
                            style={{ height: "29px", margin: "-1px 0 0 0" }}
                            disabled={
                              !auth?.checkUserAccess(
                                AccessPermissionEnum.ManageOutcomes
                              )
                            }
                            title={`${
                              translationsLoading
                                ? "Add"
                                : fetchLabelKeyTranslation("TextAdd", "Add")
                            }`}
                          >
                            {translationsLoading
                              ? "Add"
                              : fetchLabelKeyTranslation("TextAdd", "Add")}
                          </Button>
                          {visible && (
                            <UpsertCallResultDialog
                              toggleDialog={toggleDialog}
                              callResult={callResultEdit}
                              upsertCallResult={upsertCallResult}
                              reasonCodes={reasonCodes}
                            />
                          )}
                          {reasonCodePopup && (
                            <EditReasonCodeDialogBox
                              toggleReasonCodeDialog={toggleReasonCodeDialog}
                              upsertReasonCode={upsertReasonCode}
                              reasonCode={reasonCodeEdit}
                              user={auth?.user}
                              call={callResultEdit}
                            />
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="tableList float-left w-100">
                          <div className="table-container table-responsive table-overflow-hedden highlightRowPlayer borderLeftSecondChild table-mobile">
                            <Grid
                              data={orderBy(
                                callResultList
                                  ?.filter((cr) => searchCallResult(cr))
                                  .slice(page.skip, page.take + page.skip) ??
                                  [],
                                sort
                              )}
                              skip={page.skip}
                              take={page.take}
                              total={
                                callResultList.filter((item) =>
                                  searchCallResult(item)
                                ).length
                              }
                              pageable={{
                                buttonCount: 4,
                                pageSizes: true,
                              }}
                              onPageChange={handlePageChange}
                              rowRender={rowRender}
                              sortable={true}
                              sort={sort}
                              onSortChange={(e: GridSortChangeEvent) => {
                                let tempSort = e.sort;
                                if (e.sort.length === 0 && sort.length !== 0) {
                                  tempSort = sort.map((s) => {
                                    return { field: s.field, dir: "asc" };
                                  });
                                }
                                setCallResultList(
                                  orderBy(callResultList ?? [], tempSort)
                                );
                                setSort(tempSort);
                              }}
                            >
                              <GridNoRecords>
                                {loading ? (
                                  <Loader type={"infinite-spinner"} />
                                ) : (
                                  `${
                                    translationsLoading
                                      ? "No Records Available."
                                      : fetchLabelKeyTranslation(
                                          "NoRecordText",
                                          "No Records Available."
                                        )
                                  }`
                                )}
                              </GridNoRecords>
                              <GridColumn
                                width={0}
                                field="cellMobile"
                                cell={customCellMobile}
                              />
                              <GridColumn
                                sortable={true}
                                field="callResult.text"
                                title={`${
                                  translationsLoading
                                    ? "Call Outcomes"
                                    : fetchLabelKeyTranslation(
                                        "TextOutcomes",
                                        "Call Outcomes"
                                      )
                                }`}
                                cell={CustomCellTopicName}
                              />
                              <GridColumn
                                sortable={false}
                                field="CallResultReasonCode"
                                title={`${
                                  translationsLoading
                                    ? "Reason Code"
                                    : fetchLabelKeyTranslation(
                                        "ReasonCodeText",
                                        "Reason Code"
                                      )
                                }`}
                                cell={CellReasonCode}
                                headerCell={hCellReasonCode}
                              />
                              <GridColumn
                                width={250}
                                field="callResult.isArchived"
                                title={`${
                                  translationsLoading
                                    ? "Actions"
                                    : fetchLabelKeyTranslation(
                                        "ColHeaderActions",
                                        "Actions"
                                      )
                                }`}
                                cell={CustomCellWithActions}
                                sortable={true}
                              />
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default CallResults;
