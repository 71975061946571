import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../../../../components/custom/form/CustomInput";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import useMasterData from "../../../../hooks/useMasterData";
import CustomMultiSelect from "../../../../components/custom/form/CustomMultiSelect";
import { APIToken, InsertAPIToken, UpdateAPIToken } from "./APIAccessProps";
import moment from "moment";
import useAuth from "../../../../hooks/useAuth";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import {
  AccessPermission,
  RecordingNetworkAccessibilty,
} from "../../../../types/master-data";
import { AccessPermissionEnum } from "../../../../enums";
import CustomComboBox from "../../../../components/custom/form/CustomComboBox";
import { TinyObj } from "../../../../types/TinyObj";
import userGroupsService from "../../../../services/usergroups.service";
import apiService from "../../../../services/apiAccess.service";
import { Group } from "../../../../types/user-groups/Group";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../../../hooks/useSwal";
import './apiAccess.css'
import { User } from "../../../../types/user";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";


const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
}

interface UpsertAPIAccessProps {
  toggleDialog: () => void;
  upsertTokenData: APIToken | undefined;
  apiAccessUpsertHandler:(updatedToken?: APIToken, insertedToken?: APIToken) => void;
  currentUser: User | undefined;
}

const UpsertAPIAccess: React.FC<UpsertAPIAccessProps> = ({
  toggleDialog,
  upsertTokenData,
  apiAccessUpsertHandler,
  currentUser,
}) => {
  const swal=useSwal();
  const localeCtx=useLocale();
  const masterData = useMasterData();
  const auth = useAuth();
  const formRef = useRef<any>();
  const [authProfiles, setAuthProfiles] = useState<AuthorizationProfile[]>([]);
  const [userGroups, setUserGroups] = useState<TinyObj[]>([]);
  const [expiryDate,setExpiryDate]=useState<Date|null>(upsertTokenData?.tokenExpiry
    ? new Date(upsertTokenData.tokenExpiry)
    : new Date())
  const [loading,setLoading]=useState<boolean>(false);
  const [error,setError]=useState<string|undefined>();
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(upsertTokenData?true:false);
  const [dropdownDataLoader,setDropdownDataLoader]=useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
  useState<boolean>(false);
const [translations, setTranslations] = useState<
  Dictionary<string> | undefined
>(
  localeCtx?.selectedLocale?.current.componentTranslations[
    "UpsertAPIAccess"
  ]
);

useEffect(() => {
  if (
    !localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertAPIAccess"
    ]
  ) {
    fetchTranslations();
  }
}, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if(!upsertTokenData) LoadProfiles();
  }, [upsertTokenData]);

  useEffect(() => {
    if(!upsertTokenData)LoadUserGroups();
  }, [currentUser,upsertTokenData]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertAPIAccess"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertAPIAccess"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertAPIAccess");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const tokenNameValidator = (value: string) => {
    const regex=new RegExp("^[a-zA-Z0-9]*$");
    if (value.length ===0) {
      return translationsLoading
      ? "Token name cannot be empty"
      : fetchLabelKeyTranslation("TokenNameValidatorText", "Token name cannot be empty")
    }
    if(!regex.test(value)){
      return translationsLoading
      ? "Only letters and numbers are allowed"
      : fetchLabelKeyTranslation("TokenNameValidatorTextTwo", "Only letters and numbers are allowed")
    }
    return "";
  };

  const authProfileValidator = (value: string) => {
    if (!value) {
      return translationsLoading
      ? "Permission group is required"
      : fetchLabelKeyTranslation("PermissionGroupValidatorText", "Permission group is required")
    }
    return "";
  };

  const recordingNetworkAccessibilityValidator = (value: string) => {
    if (!value) {
      return translationsLoading
      ? "Recording network is required"
      : fetchLabelKeyTranslation("RecordingNetworkValidatorText", "Recording network is required")
    }
    return "";
  };

  const LoadProfiles = async () => {
    setDropdownDataLoader(true);
    try {
      const data = await authorizationProfileService.getAuthorizationProfiles();
      mapAuthProfileLocalization(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
      setDropdownDataLoader(false);
    }
  };

  const mapAuthProfileLocalization = (
    _authProfiles: AuthorizationProfile[] | undefined
  ) => {
    if (_authProfiles !== undefined) {
      _authProfiles = _authProfiles.filter((ap) => {
        const findSecurityControlPermission = ap.permissions.find(
          (permission: AccessPermission) => permission.id === 14
        );
        if (
          !findSecurityControlPermission ||
          auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl)
        ) {
          ap.localizationValue = ap.isSystemDefined
            ? fetchLabelKeyTranslation(apLocaleKeyName[ap.name], ap.name)
            : ap.name;
          return ap;
        }
      });
      setAuthProfiles(_authProfiles);
    }
  };

  const LoadUserGroups = async () => {
    setDropdownDataLoader(true);
    try {
      const data = await userGroupsService.getUserGroups();
      const groupData = data?.filter((ele) => {
        if (
          auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ||
          currentUser?.associatedGroups?.playbackNetwork?.find(
            (playback: TinyObj) => playback.id === ele.id
          )
        ) {
          let gp: TinyObj = {
            id: ele.id,
            name: ele.name,
          };
          return gp;
        }
      });
      setUserGroups(groupData);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
      setDropdownDataLoader(false);
    }
  };


  const handleAdd = async (newTokenData: InsertAPIToken) => {
    setLoading(true);
    setError(undefined);
    try{
      const createNewToken:APIToken=await apiService.insertNewToken(newTokenData,auth?.tokenPayload?.CustomerId??0);
      toggleDialog();
      const swalOptions: SweetAlertOptions<any, any> = {
        allowOutsideClick:false,
        icon: "success",
        title:translationsLoading
        ? "Token has been generated"
        : fetchLabelKeyTranslation("SwtSuccessInsertTitle", "Token has been generated"),
        html:`
        <div id="parent">
        <input value='${createNewToken.token}' type='text' disabled={true} />
        <br />
        <div style="margin-top:14px;">
        <i style="color:#5c5c5c;" class="bi bi-info-circle"></i>
        <span style="color:#5c5c5c;">${translationsLoading
          ? "Copy this token before closing this dialog (you won't be able to see the token later)."
          : fetchLabelKeyTranslation("SwtSuccessInsertInfoText", "Copy this token before closing this dialog (you won't be able to see the token later).")}</span>
        </div>
        </div>
        `,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText:translationsLoading
        ? "Cancel"
        : fetchLabelKeyTranslation("SwtSuccessInsertCancelText", "Cancel"),
        confirmButtonColor: "#3085d6",
        confirmButtonText:translationsLoading
        ? "Copy to clipboard"
        : fetchLabelKeyTranslation("SwtSuccessInsertConfirmText", "Copy to clipboard")
      };
      const swalData=await swal.fire(swalOptions);
      if(swalData.isConfirmed){
        navigator.clipboard.writeText(createNewToken.token);
      }
      apiAccessUpsertHandler(undefined,createNewToken);
    }catch(err){
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "error",
        title:translationsLoading
        ? "Token cannot be created"
        : fetchLabelKeyTranslation("SwtFailureInsertTitle", "Token cannot be created"),
        text:translationsLoading
        ? "Please try again later"
        : fetchLabelKeyTranslation("SwtFailureInsertText", "Please try again later"),
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText:translationsLoading
        ? "Okay"
        : fetchLabelKeyTranslation("SwtFailureInsertConfirmText", "Okay")
      };
      toggleDialog();
      swal.fire(swalOptions);
    }finally{
      setLoading(false);
    }
  };

  const handleUpdate = async(updateTokenData: UpdateAPIToken) => {
    setLoading(true);
    setError(undefined);
    try{
      const updateToken=await apiService.updateToken(upsertTokenData?.id??0,auth?.tokenPayload?.CustomerId??0,updateTokenData);
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "success",
        title:translationsLoading
        ? "Token updated"
        : fetchLabelKeyTranslation("SwtSuccessUpdateTitle", "Token updated"),
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText:translationsLoading
        ? "Okay"
        : fetchLabelKeyTranslation("SwtSuccessUpdateConfirmText", "Okay")
      };
      toggleDialog();
      await swal.fire(swalOptions);
      apiAccessUpsertHandler(updateToken,undefined);
    }catch(err){
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "error",
        title:translationsLoading
        ? "Token cannot be updated"
        : fetchLabelKeyTranslation("SwtFailureUpdateTitle", "Token cannot be updated"),
        text:translationsLoading
        ? "Please try again later"
        : fetchLabelKeyTranslation("SwtFailureUpdateText", "Please try again later"),
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText:translationsLoading
        ? "Okay"
        : fetchLabelKeyTranslation("SwtFailureUpdateConfirmText", "Okay")
      };
      toggleDialog();
      swal.fire(swalOptions);
    }finally{
      setLoading(false);
    }
  };

  const submitHandler = () => {
    const form = formRef.current as Form;
    const newTokenData:InsertAPIToken={
      tokenName:form.values?.tokenName,
      tokenExpiry:moment(expiryDate).format("YYYY-MM-DD HH:mm:ss"),
      authorizationProfileName:form.values?.authProfiles?.name,
      recordingNetworkAccessibility:form.values?.addViaPeople?.name,
      userRecordingGroups:form.values?.addViaGroup?.reduce((acc:TinyObj[],curr:Group)=>{
        const tempGroup:TinyObj={
          createdAt:curr.createdAt,
          createdBy:curr.createdBy,
          id:curr.id,
          name:curr.name,
          updatedAt:curr.updatedAt,
          updatedBy:curr.updatedBy,
        }
        return [...acc,tempGroup]
      },[])
    }
    const updateTokenData:UpdateAPIToken={
      tokenExpiry:moment(expiryDate).format("YYYY-MM-DD HH:mm:ss"),
    }
    !upsertTokenData
      ? handleAdd(newTokenData)
      : handleUpdate(updateTokenData);
  };

  const compareForExpiryDate=(date:Date)=>{
    const oldDate=upsertTokenData && new Date(upsertTokenData?.tokenExpiry);
    if (
      upsertTokenData && oldDate?.getDate()===date.getDate() && oldDate?.getMonth()===date.getMonth() && oldDate?.getFullYear()===date.getFullYear()
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  }

  return (
    <Form
      ref={formRef}
      initialValues={{
        tokenName: upsertTokenData?.tokenName ?? "",
        authProfiles: upsertTokenData?.authorizationProfile ?? undefined,
        addViaPeople:upsertTokenData?.recordingNetworkAccessibility??undefined,
        addViaGroup:upsertTokenData?.recordingUserGroups?? []
      }}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement style={{ maxWidth: 650 }}>
            <Dialog
            closeIcon={false}
              title={!upsertTokenData ? translationsLoading
                ? "Add new token"
                : fetchLabelKeyTranslation("DialogNewTokenTitle", "Add new token") : translationsLoading
                ? "Edit token"
                : fetchLabelKeyTranslation("DialogEditTokenTitle", "Edit token")}
              onClose={toggleDialog}
            >
              <div className="formAdd" style={{ marginBottom: "0px" }}>
                {error && <span className="tx-red">{error}</span>}
                <div className="formBoxRow p-t-5 p-b-5">
                  <div className="formBoxAction">
                    <div className="formBoxLabel fs-14">{translationsLoading
                ? "Token name"
                : fetchLabelKeyTranslation("DialogTokenNameTitle", "Token name")}</div>
                    <div className="formInput">
                      <Field
                        id="tokenName"
                        name="tokenName"
                        style={{ height: "32px" }}
                        value={formRenderProps.valueGetter("tokenName")}
                        placeholder={translationsLoading
                          ? "Enter new token"
                          : fetchLabelKeyTranslation("DialogTokenNamePlaceholderText", "Enter new token")}
                        component={CustomInput}
                        disabled={upsertTokenData}
                        validator={tokenNameValidator}
                      />
                    </div>
                  </div>
                </div>
                <div className="formBoxRow p-t-5 p-b-5">
                  <div className="formBoxAction">
                    <div className="formBoxLabel fs-14">{translationsLoading
                ? "Expiry Date"
                : fetchLabelKeyTranslation("DialogExpiryDateTitle", "Expiry Date")}</div>
                    <div className="formInput">
                      <DatePicker
                        value={expiryDate}
                        min={new Date()}
                        max={moment(new Date()).add(1,'y').toDate()}
                        onChange={(event) =>{
                          if(event.value!==null){
                            setExpiryDate(event.value)
                            compareForExpiryDate(event.value);
                          }
                        }}
                        format="dd/MM/yyyy"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                <div className="formBoxRow p-t-5 p-b-18">
                  <div className="formBoxAction">
                    <div className="formBoxLabel fs-14">{translationsLoading
                ? "Permission Groups"
                : fetchLabelKeyTranslation("DialogPermissionGroupsTitle", "Permission Groups")}</div>
                    <div className="formInput">
                      <Field
                        id="authProfiles"
                        name="authProfiles"
                        placeholder={translationsLoading
                          ? "Select Permission Group"
                          : fetchLabelKeyTranslation("DialogPGPlaceholderText", "Select Permission Group")}
                        data={authProfiles}
                        value={formRenderProps.valueGetter("authProfiles")}
                        component={CustomComboBox}
                        textField="name"
                        filtering={true}
                        sorting={true}
                        sortField={"name"}
                        disabled={upsertTokenData || dropdownDataLoader}
                        validator={authProfileValidator}
                        loading={dropdownDataLoader}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "2px dashed rgba(0, 0, 0, 0.2)",
                    padding: "9px 0 9px 0",
                  }}
                  className="float-left w-100"
                >
                  <div className="formBox">
                    <div className="formBoxRow fs-14 font-weight-bold">
                    {translationsLoading
                ? "Edit Playback Network"
                : fetchLabelKeyTranslation("DialogPBNetworkTitle", "Edit Playback Network")}
                    </div>
                    <div className="formBoxRow p-t-5 p-b-1">
                      <div className="formBoxLabel fs-14">{translationsLoading
                ? "Via People"
                : fetchLabelKeyTranslation("DialogViaPeopleTitle", "Via People")}</div>
                      <div className="formBoxAction">
                        <div className="formInput">
                          <Field
                            id="addViaPeople"
                            name="addViaPeople"
                            data={masterData?.data?.recordingNetworkAccessibilties.filter(
                                (accessbility: RecordingNetworkAccessibilty) =>
                                accessbility.id !== 4 ||
                                auth?.checkUserAccess(
                                  AccessPermissionEnum.ManageSecurityControl
                              )
                            )}
                            style={{ height: "32px" }}
                            value={formRenderProps.valueGetter("addViaPeople")}
                            component={CustomComboBox}
                            textField="name"
                            disabled={upsertTokenData}
                            validator={recordingNetworkAccessibilityValidator}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formBoxRow p-t-5 p-b-15">
                      <div className="formBoxLabel fs-14">{translationsLoading
                ? "Via User Groups"
                : fetchLabelKeyTranslation("DialogViaUserGroupsTitle", "Via User Groups")}</div>
                      <div className="formBoxAction">
                        <div className="formInput">
                          <Field
                            id="addViaGroup"
                            name="addViaGroup"
                            data={userGroups}
                            value={formRenderProps.valueGetter("addViaGroup")}
                            component={CustomMultiSelect}
                            textField="name"
                            filtering={true}
                            sorting={true}
                            sortField={"name"}
                            disabled={upsertTokenData || dropdownDataLoader}
                            loading={dropdownDataLoader}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DialogActionsBar>
                <Button disabled={loading} onClick={toggleDialog}>
                {translationsLoading
                ? "Cancel"
                : fetchLabelKeyTranslation("DialogCancelBtnText", "Cancel")}
                </Button>
                <Button
                  onClick={submitHandler}
                  className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white ${loading || buttonStateTracker || !formRenderProps.valid || dropdownDataLoader ? "disabledBtn":""}`}
                  disabled={loading || !formRenderProps.valid || buttonStateTracker || dropdownDataLoader}
                >
                  {false ? (
                    <Loader
                      themeColor={"primary"}
                      size={"small"}
                      type={"infinite-spinner"}
                    />
                  ) : (
                    `${upsertTokenData ? translationsLoading
                      ? "Save"
                      : fetchLabelKeyTranslation("DialogSaveBtnText", "Save") : translationsLoading
                        ? "Add"
                        : fetchLabelKeyTranslation("DialogAddBtnText", "Add")}`
                  )}
                </Button>
              </DialogActionsBar>
            </Dialog>
          </FormElement>
        );
      }}
    />
  );
};

export default UpsertAPIAccess;
