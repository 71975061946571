import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usersService from "../../services/users.service";
import { Dictionary } from "../../types/Dictionary";
import {
  InsightMetricKeyLabel,
  InsightViewByTblData,
} from "../../types/insight";
import { TinyUser } from "../../types/user";
import {
  concatUsername,
} from "../../utils/profileUtils";
import useSwal from "../../hooks/useSwal";
import useTranslation from "../../hooks/useTranslation";

interface CallColorCountPair {
  color: string;
  count: number;
}

interface InsightViewChartProps {
  viewFilterTblData?: InsightViewByTblData;
  viewByDataLoading: boolean;
  viewByMetric: InsightMetricKeyLabel;
}

const colors = ["Yellow", "Red", "Green", "Orange", "Blue", "Purple"];
const colors2 = ["#5CB0BA"];

const processMetricKeyForDisplay = (
  metricKeyLabel: string,
  metricKey: string
) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (metricKeyLabel === "Hour") {
    const hour = Number(metricKey);
    return `${hour > 9 ? `${hour}:00` : `0${hour}:00`}`;
  } else if (metricKeyLabel === "Day") {
    return days[Number(metricKey)];
  } else if (metricKeyLabel === "Month") {
    return months[Number(metricKey) % months.length];
  } else if (metricKeyLabel === "Week") {
    return `WC ${metricKey}`;
  }

  return metricKey;
};

const InsightViewChart: React.FC<InsightViewChartProps> = ({
  viewFilterTblData,
  viewByDataLoading,
  viewByMetric,
}) => {
  const trans=useTranslation("InsightViewChart");
  const localeCtx = useLocale();
  const swal = useSwal();

  const [labels, setLabels] = useState<any[]>([]);
  const [labelCounts, setLabelCounts] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshChart, setRefreshChart] = useState<boolean>(false);

  useEffect(() => {
    const buildChartData = async () => {
      setLabels([]);
      setLabelCounts([]);
      if (viewFilterTblData) {
        if (
          viewFilterTblData.rows.length > 0 &&
          viewFilterTblData.rows[0].cells.length > 0
        ) {
          setLoading(true);
          const filterdRows = viewFilterTblData.rows.filter((row) => row.cells[0].metricKeyCounts[0].totalCount > 0);
          viewFilterTblData.rows = [...filterdRows]
    
          const keys: string[] = [];
          const values: number[] = [];
          const colorCountPair: CallColorCountPair[] = [];
          const uniqueUserIds: string[] = [];
          if (viewByMetric.name === "Users" || viewByMetric.name === "Evaluators") {
            viewFilterTblData.rows.forEach((r) => {
              const id = r.cells[0].metricKeyCounts[0].metricKey;
              if (!uniqueUserIds.includes(id)) {
                uniqueUserIds.push(id);
              }
            });
          }

          let tinyUsers: { [key: string]: TinyUser } = {};
          let error = false;
          try {
            const res = await usersService.getTinyUsers(uniqueUserIds);
            tinyUsers = res;
          } catch (err) {
            console.error(err);
            error = true;
          }

          viewFilterTblData.rows.forEach((r) => {
            const metric =
              r.cells[0].metricKeyCounts.length > 0
                ? r.cells[0].metricKeyCounts[0]
                : { metricKey: "", totalCount: 0 };
            if (viewByMetric.name !== "Users" && viewByMetric.name !== "Evaluators" ) {
              keys.push(
                processMetricKeyForDisplay(viewByMetric.name, metric.metricKey)
              );
            } else {
              if (!error) {
                const userInfo = tinyUsers[metric.metricKey];
                const name = concatUsername(
                  userInfo?.firstName ? userInfo.firstName : "",
                  userInfo?.lastName ? userInfo?.lastName : ""
                ).trim();
                keys.push(name.length > 0 ? name : userInfo.email);
              }
            }
            values.push(metric.totalCount);
            colorCountPair.push({
              color: metric.metricKey,
              count: metric.totalCount,
            });
          });
          if (viewByMetric.displayName === "Flags") {
            setLabels(colors);
            const countForFlags: number[] = [];
            colors.forEach((color: string) => {
              const colorCountPairValue = colorCountPair.find(
                (pair: CallColorCountPair) => pair.color === color
              );
              if (colorCountPairValue) {
                countForFlags.push(colorCountPairValue.count);
              } else {
                countForFlags.push(0);
              }
            });
            setLabelCounts(countForFlags);
          } else {
            setLabels(
              viewByMetric.displayName === "Moments" ||
                viewByMetric.displayName === "Topics"
                ? keys.map((key) => key.substring(4))
                : keys
            );
            setLabelCounts(values);
          }
          setLoading(false);
          setRefreshChart(true);
        }
      } else {
        setRefreshChart(false);
      }
    };

    buildChartData();
  }, [viewFilterTblData]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightViewChart"
      ]
    ) {
      trans.fetchTranslations("InsightViewChart");
    }
  }, [localeCtx?.selectedLocale]);

  const handleChartRefresh = (
    chartOptions: any,
    themeOptions: any,
    chartInstance: any
  ) => {
    if (refreshChart) {
      chartInstance.setOptions(chartOptions, themeOptions);
    }
  };

  const getColor = (point: any) => {
    if (viewByMetric.displayName == "Sentiments") {
      if (point.category == "Negative") return "#dc3545";
      else if (point.category == "Neutral") return "#ffc107";
      else if (point.category == "Positive") return "#28a745";
      else return "#5CB0BA";
    } else if (viewByMetric.displayName == "Flags") {
      if (point.category == "Blue") return "#0000ff";
      else if (point.category == "Red") return "#dc3545";
      else if (point.category == "Yellow") return "#ffff00";
      else if (point.category == "Green") return "#07d11b";
      else if (point.category == "Orange") return "#ffa500";
      else if (point.category == "Purple") return "#a020f0";
      else return "#5cb0ba";
    } else return "#5CB0BA";
  };

  return (
    <>
      {viewByDataLoading || loading ? (
        <div
          className="radius-6 border-black-3 border-w-2 border-dashed"
          style={{
            height: 320,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type={"infinite-spinner"} themeColor={"primary"} />
          <span></span>
        </div>
      ) : (
        <Chart
          style={{ height: 320 }}
          onRefresh={handleChartRefresh}
          seriesColors={colors2}
        >
          <ChartLegend position={"bottom"} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{ rotation: "auto" }}
              categories={labels}
            />
          </ChartCategoryAxis>
          <ChartTooltip />
          <ChartSeries>
            <ChartSeriesItem
              name={`${
                trans.translationsLoading
                  ? "Calls by"
                  : trans.fetchLabelKeyTranslation("CallsByText", "Calls by")
              } ${viewByMetric.displayName}`}
              data={labelCounts}
              color={getColor}
            />
          </ChartSeries>
        </Chart>
      )}
    </>
  );
};

export default InsightViewChart;
